import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useGroupChatMessageStore = defineStore('groupChatMessage', {
  actions: {
		destroy: (id) => axios.delete(`${VUE_APP_ENDPOINT_URL}/api/admin/group-chat-messages/${id}`),
    index: (event = {}, groupChatId) => {
      event.page = event.page ? event.page + 1 : 1
      event.sortField = event.sortField || 'id'
      event.sortOrder = event.sortOrder || -1

			return axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/group-chat-messages`, {
        params: {
          group_chat_id: groupChatId,
          ...event
        }
      })
		},
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/group-chat-messages/${id}`),
    store: (groupChatMessage) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/group-chat-messages`, groupChatMessage),
		update: (id, groupChatMessage) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/group-chat-messages/${id}`, groupChatMessage),
  }
})
