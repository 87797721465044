import toast from '@/plugins/toast'

const requesting = ({ request, loading = null, successMessage = 'Successfully.', callback = (() => {}) }) => {
	if (! request) {
		toast.add({ severity:'error', summary: 'Error', detail: 'Request is required.', life: 3000 })
		return
	}

	if (loading !== null) {
		loading.value = true
	}

	request.then(({ data }) => {
		if (data.error) {
			toast.warning({ message: data.error.message })
			return
		}

		toast.success({ message: successMessage })
		callback(data)
	}).catch((e) => {
		toast.error(e)
	}).finally(() => {
		if (loading !== null) {
			loading.value = false
		}
	})
}

export {
	requesting,
}
