<script setup>
import { onMounted, ref } from "vue"
import { useConfirm } from "primevue/useconfirm"
import { useGroupChatMessageStore } from "@/store/group-chat/group-chat-message"
import { useOfficialChatStore } from "@/store/group-chat/official-chat"
import { useRoute } from 'vue-router'

import { providers } from "@/config"
import { requesting } from "@/scripts/request"

const confirm = useConfirm()
const route = useRoute()

const groupChatMessageStore = useGroupChatMessageStore()
const officialChatStore = useOfficialChatStore()

const groupChatId = ref(null)
const officialChat = ref({})
const messages = ref([])

const loading = ref(true)

const optionBoolean = [
  { label: "Yes", value: true },
  { label: "No", value: false }
]

const totalRecords = ref(0)

const confirmDelete = (item) => {
	confirm.require({
		message: 'Are you sure you want to delete?',
		header: 'Confirmation',
		accept: () => {
			requesting({
				request: groupChatMessageStore.destroy(item.id),
				loading,
				callback: () => search()
			})
		}
	});
}

const initial = () => {
  groupChatId.value = route.params.id
  officialChatStore.show(groupChatId.value).then((res) => {
    officialChat.value = res.data.official_chat

    search()
  })
}

const search = (event) => {
  loading.value = true

  groupChatMessageStore.index(event, groupChatId.value).then((res) => {
    messages.value = res.data.group_chat_messages.data
    totalRecords.value = res.data.group_chat_messages.total

    loading.value = false
  })
}

onMounted(() => {
  initial()
})

</script>

<template>
  <div class="block-section">
    <div class="block-header">
        <span v-if="groupChatId" class="block-title">
            <div>Show #{{ groupChatId }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>

        <br/>
    </div>

    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <h5>Official Chat</h5>

          <label class="block text-900 font-medium mb-2" for="picture_group_url">Picture</label>
          <Avatar :image="officialChat.picture_group_url" size="xlarge" shape="circle" class="mb-3" />

          <label class="block text-900 font-medium mb-2" for="title">Title</label>
          <InputText v-model="officialChat.title" id="title" type="text" class="w-full mb-3" readonly />

          <label class="block text-900 font-medium mb-2" for="latest_message">Latest Message</label>
          <InputText v-model="officialChat.latest_message" id="latest_message" type="text" class="w-full mb-3" readonly />

          <label class="block text-900 font-medium mb-2" for="can_join">Join</label>
          <SelectButton v-model="officialChat.can_join" id="can_join" class="w-full mb-3" :options="optionBoolean" optionLabel="label" optionValue="value" disabled />
          <div class="mb-3" />

          <label class="block text-900 font-medium mb-2" for="can_message">Message</label>
          <SelectButton v-model="officialChat.can_message" id="can_message" class="w-full mb-3" :options="optionBoolean" optionLabel="label" optionValue="value" disabled />
          <div class="mb-3" />

          <label class="block text-900 font-medium mb-2" for="active">Active</label>
          <SelectButton v-model="officialChat.active" id="active" class="w-full mb-3" :options="optionBoolean" optionLabel="label" optionValue="value" disabled />
          <div class="mb-3" />

          <label class="block text-900 font-medium mb-2" for="type">Type</label>
          <InputText v-model="officialChat.type" id="type" type="text" class="w-full mb-3" readonly />

          <label class="block text-900 font-medium mb-2" for="providers">Providers</label>
          <MultiSelect v-model="officialChat.providers" display="chip" :options="providers" class="w-full mb-2" disabled />

          <label class="block text-900 font-medium mb-2" for="tags">Tags</label>
          <MultiSelect v-model="officialChat.tags" display="chip" :options="officialChat.tags" class="w-full mb-2" disabled />

        </div>
      </div>
    </div>

    <div class="block-content">
      <div class="grid">
        <div class="col-12">
          <div class="card">
            <h5>
              Messages <Button label="Create" icon="pi pi-plus" class="p-button-sm ml-3" @click="$router.push({ name: 'official-chats.messages.create', params: { groupChatId }})" />
            </h5>
            <DataTable
              :loading="loading"
              :lazy="true"
              :paginator="true"
              :rowHover="true"
              :rows="10"
              sortField="id"
              :sortOrder="-1"
              :totalRecords="totalRecords"
              :value="messages"

              @page="search($event)"
            >
              <template #empty>
                No records.
              </template>
              <template #loading>
                Loading. Please wait.
              </template>

              <Column header="Avatar">
                <template #body="{data}">
                  <Avatar :image="data.avatar_url" size="xlarge" shape="circle" />
                </template>
              </Column>

              <Column field="name" header="Name" />

              <Column field="message" header="Message" />

              <Column field="loves" header="Loves" />

              <Column field="views" header="Views" />

              <Column  header="Created">
                <template #body="{data}">
                  {{ data.created_at ? new Date(data.created_at).toLocaleString() : '' }}
                </template>
              </Column>

              <Column header="Options">
                <template #body="{data}">
                  <router-link :to="`/official-chats/messages/${data.id}/edit`">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2 mb-2" />
                  </router-link>

									<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2 mb-2" @click="confirmDelete(data)" />
                </template>
              </Column>

            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
