const providers = [
	'axi',
	'exness',
	'fbs',
	'fxgt',
	'hfm',
	'xm',
];

export {
	providers,
}
