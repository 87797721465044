import { app } from '@/main'

const lifeTime = 5000

const error = (e, loading = null) => {
	if (loading) {
		loading.value = false
	}

  let data = {}
  if (! e.response) {
		const text = e.stack.replace(/<[^>]+>/g, '').substring(0, 100)
    data = {
      severity: 'error',
      summary: `${e.name} ${e.message}`,
      detail: `${text.substring(0, 100)}${text.length > 0 ? '...' : ''}`,
      life: lifeTime
    }
    if (process.env.NODE_ENV === 'development') {
      console.log('! e.response', e)
    }
  } else if (e.response.status >= 500) {
		const text = e.response.data.replace(/<[^>]+>/g, '').substring(0, 100)
    data = {
      severity: 'error',
      summary: `${e.response.status} ${e.response.statusText}`,
      detail: `${text.substring(0, 100)}${text.length > 0 ? '...' : ''}`,
      life: lifeTime
    }
    if (process.env.NODE_ENV === 'development') {
      console.log('e.response.status >= 500', e)
    }
  } else if (e.response.status >= 400) {
    data = {
      severity: 'warn',
      summary: `${e.response.status} ${e.response.statusText}`,
      detail: e.response.data.error ? e.response.data.error.message : e.message,
      life: lifeTime
    }

    if (loading) {
      loading.value = false
    }
  } else {
    data = {
      severity: 'info',
      summary: `${e.response.status} ${e.response.statusText}`,
      detail: e.response.data,
      life: lifeTime
    }

    if (loading) {
      loading.value = false
    }
  }

  app.config.globalProperties.$toast.add(data)
}

const errorForm = (v$) => {
  // @vuelidate/validators
  v$.value.$errors.forEach(error => {
    app.config.globalProperties.$toast.add({
      severity: 'warn',
      summary: `# ${error.$property}`,
      detail: error.$message,
      life: lifeTime
    })
  })
}

const success = ({ changed = null, message = 'Successfully', loading = null, response = null, title = 'Success' }) => {
  const summary = response ? `${response.status} ${response.statusText}` : title
  const detail = response ? ( response.data.message || message ) : message
  app.config.globalProperties.$toast.add({
    severity: 'success',
    summary,
    detail,
    life: lifeTime
  })

  if (changed) {
    changed.value = false
  }

  if (loading) {
    loading.value = false
  }
}

const warning = ({ message = 'Warning', loading = null, response = null, title = 'Warning' }) => {
  const summary = response ? `${response.status} ${response.statusText}` : title
  const detail = response ? ( response.data.message || message ) : message
  app.config.globalProperties.$toast.add({
    severity: 'warn',
    summary,
    detail,
    life: lifeTime
  })

  if (loading) {
    loading.value = false
  }
}

export default {
  error,
  errorForm,
  success,
	warning,
}
